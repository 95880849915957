<template>
  <div class="userinfo">
    <div class="head">
      <span>用户基本信息</span>
    </div>
    <div class="content">
      <div class="green"></div>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" size="small" v-model="sfzh">
      </el-input>
      <div class="scroll">
        <!-- <div class="scroll-title">
          <div class="flex">序号</div>
          <div class="flex flex1">姓名</div>
          <div class="flex flex3">身份证号</div>
          <div class="flex">性别</div>
          <div class="flex">年龄</div>
          <div class="flex flex2">最近随访日期</div>
          <div class="flex flex2">建档日期</div>
          <div class="flex flex2">电话</div>
          <div class="flex flex3">乡镇/社区</div>
          <div class="flex flex3">村/服务站</div>
        </div>
        <div class="scroll-line" v-for="(item, index) in 40" :key="index" @click="openSeeBasicInfo" @dblclick="dblClick">
          <div class="flex">01</div>
          <div class="flex flex1">李明明</div>
          <div class="flex flex3">130133199909090909</div>
          <div class="flex">男</div>
          <div class="flex">88</div>
          <div class="flex flex2">2023-01-01</div>
          <div class="flex flex2">2023-01-01</div>
          <div class="flex flex2">12121212</div>
          <div class="flex flex3">乡镇/社区</div>
          <div class="flex flex3">村/服务站</div>
        </div> -->
        <div class="scroll-item">
          <div class="scroll-item-line">
            <div class="scroll-text">
              <span>序号：01</span>
            </div>
            <div class="scroll-text">
              <span>姓名：-</span>
            </div>
          </div>
          <div class="scroll-item-line">
            <div class="scroll-text">
              <span>年龄：-</span>
            </div>
            <div class="scroll-text">
              <span>性别：-</span>
            </div>
          </div>
          <div class="scroll-item-line">
            <div class="scroll-text scroll-text1">
              <span>人群分类：-</span>
            </div>
          </div>
          <div class="scroll-item-line">
            <div class="scroll-text scroll-text1">
              <span>所属机构：-</span>
            </div>
          </div>
          <div class="scroll-item-line">
            <div class="scroll-text scroll-text1">
              <span>身份证号：-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SeeBasicInfo v-if="showSeeBasicInfo" @close="closeSeeBasicInfo"></SeeBasicInfo>
  </div>
</template>

<script>
import SeeBasicInfo from './see-basic-Info.vue'
export default {
  components: {
    SeeBasicInfo
  },
  data () {
    return {
      showSeeBasicInfo: false,
      timer: null,
      sfzh: ''
    }
  },
  methods: {
    openSeeBasicInfo () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.showSeeBasicInfo = true
      }, 400)
    },
    closeSeeBasicInfo () {
      this.showSeeBasicInfo = false
    },
    dblClick () {
      clearTimeout(this.timer)
    }
  }
}
</script>

<style scoped lang="less">
.userinfo {
  width: 100%;
  height: 100%;
  padding-top: 18px;
  // background-color: #081C38;
  // background-color: rgb(219, 216, 216);
  background-color: #25A0FF;
  // background-color: #fff;
  box-sizing: border-box;

  .head {
    width: 100%;
    height: 35px;
    line-height: 35px;
    margin-bottom: 8px;
    padding-left: 34px;
    padding-top: 5px;
    box-sizing: border-box;
    background: url('../../views/zhyl/img/dialogTitleBg.png') no-repeat;
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100% - 50px);
    padding-top: 18px;
    // background-color: rgba(29, 64, 112, .33);
    border-radius: 6px;
    box-sizing: border-box;

    .green {
      position: absolute;
      top: 0;
      left: 7px;
      z-index: 88;
      width: 60px;
      height: 2px;
      background-color: #44D7B6;
      border-radius: 2px;
    }

    .el-input {
      width: 95%;

      /deep/.el-input__inner {
          background-color: #fff;
          border-radius: 6px;
          border: 1px solid #1F72D3;
        }

        /deep/.el-input__inner::placeholder {
          color: #999999;
        }

        /deep/.el-input__suffix {
          color: #999999;
        }
    }

    .scroll {
      overflow: scroll;
      width: 100%;
      height: calc(100% - 50px);
      margin-top: 10px;

      .scroll-title {
        position: sticky;
        top: 0;
        background-color: rgba(29, 64, 112);
      }

      .scroll-title,
      .scroll-line {
        display: flex;
        align-items: center;
        width: 1300px;
        height: 40px;
        color: #01EEFD;
        font-size: 16px;
        cursor: pointer;

        .flex {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 70px;
          text-align: center;
        }

        .flex1 {
          width: 100px;
        }

        .flex2 {
          width: 180px;
        }

        .flex3 {
          width: 300px;
        }
      }

      .scroll-line {
        font-size: 14px;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, .4);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }

      .scroll-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 99%;
        height: 136px;
        margin: 0 auto;
        padding: 10px 5px;
        // background: rgba(1,118,204,1);
        background: #fff;
        box-sizing: border-box;

        .scroll-item-line {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .scroll-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 50%;
            color: #000;
            font-size: 14px;
          }

          .scroll-text1 {
            width: 100%;
          }
        }
      }
    }
  }

  /deep/.el-dialog {
    width: 100%;
    height: 100%;
    background-color: red;
  }
}
</style>
